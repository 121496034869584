/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Subtitle, Text } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Domluvit schůzku"}>
        <SiteHeader />

        <Column className="--style2 --left --full" name={"einleitung"} style={{"backgroundColor":"var(--color-blend--95)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--1 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1310}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Einen Termin vereinbaren"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style2 fs--18" style={{"maxWidth":1260}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit","align":"right"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Psychotherapeutische Praxis"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>Mgr. Elena Fürst</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":375}} content={"Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>+49 797 811 2X05</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Oeder Weg 22<br>info@vase-stranky.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}